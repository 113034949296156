//import '../../../../node_modules/jquery/dist/jquery.js';
//require('../../../../node_modules/bootstrap/dist/js/bootstrap.js');

// You can specify which plugins you need
//import { Nav, Navbar, Tooltip, Toast, Popover } from 'bootstrap';
//import Alert from 'bootstrap';

//require('../../../../node_modules/slick-carousel/slick/slick.js');


$(function() {

    /* mobileNav */
    $( "#burger" ).click(function(event) {
        event.preventDefault();
        $(".mobileNav").css({"width": "100%"});
    });
    
    $( "#navClose" ).click(function(event) {
        event.preventDefault();
        $(".mobileNav").css({"width": "0"});
    });

    $( ".subMenuOpen" ).click(function(event) {
        event.preventDefault();
        
        //If already open, close it
        if ($(this).hasClass("Opened")) {
            $(this).removeClass("Opened");
            $("#subMenuGroup"+$(this).attr("subNav")).css({"height": "43"});
            return;
        }

        //Else close all other submenus
        $(".subMenuOpen").removeClass("Opened");
        $(".subMenuGroup").css({"height": "43"});

        //Then open the one clicked
        $(this).addClass("Opened");
        $("#subMenuGroup"+$(this).attr("subNav")).css({"height": "auto"});
    });
   

    /* desktopNav */
    /*$(".headerNavLink").click(function(event){
        event.preventDefault();
        $("#deskSubNav"+$(this).attr("subNav")).show();
    });*/

    
    $(".headerNavLink").hover(function(){
        $("#deskSubNav"+$(this).attr("subNav")).show();
        $(this).children(".underline").addClass("showUnderline");
    }, function(){
        $("#deskSubNav"+$(this).attr("subNav")).hide();
        $(this).children(".underline").removeClass("showUnderline");
    });

    $(".subHeader").hover(function(){
        $(this).show();
        $("#headNav"+$(this).attr("headNav")).addClass("showUnderline");
    }, function(){
        $(this).hide();
        $("#headNav"+$(this).attr("headNav")).removeClass("showUnderline");
    });

    $( ".no-redirect" ).click(function(event) {
        event.preventDefault();
    });
    

    //Down Arrow
    $( "#downArrow" ).click(function(event) {
        event.preventDefault();
        document.getElementById("scrollTo").scrollIntoView();
    });

    function isScrolledIntoView(elem)
    {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();

        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();

        //console.log('docViewTop',docViewTop);
        //console.log('elemTop',elemTop);

        if ( $( window ).width() < 768 ) {
            return true
        };

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }

    $(window).scroll(function(){
        const showBubbles = isScrolledIntoView($(".bubbleDiv"));
        //console.log(showBubbles);
        if (showBubbles) {
            $("#bub1").css({"animation": "bub1 2s ease 0s 1 normal forwards"});
            $("#bub2").css({"animation": "bub2 2s ease 0s 1 normal forwards"});
            $("#bub3").css({"animation": "bub3 2s ease 0s 1 normal forwards"});
            $("#bub4").css({"animation": "bub4 2s ease 0s 1 normal forwards"});
            $("#bub5").css({"animation": "bub5 2s ease 0s 1 normal forwards"});
            $("#bub6").css({"animation": "bub6 2s ease 0s 1 normal forwards"});
            $("#bub7").css({"animation": "bub7 2s ease 0s 1 normal forwards"});
        }
    });

    //Slider
    $('#featureCarousel').slick({
        speed: 9000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    });
});
